<template>
	<div class="add-node-form form-closed" id="add-node-form">
		<form id="add-node-form" novalidate>
			<div class="col left">
				<div class="controls two-column">
					<ul>
						<li>
							<a class="save" @click.prevent="saveContentTree">Save <i class="fa-solid fa-floppy-disk"></i></a>
						</li>
						<li>
							<a class="close" href="#" @click.prevent="confirmCloseAddNode()">Close <i class="fa-solid fa-circle-xmark"></i></a>
						</li>
					</ul>
				</div>
				<!-- end controls -->
				<div class="fieldset-links">
					<p class="subject-title">{{ fieldValue("title", "title") }}</p>
					<ul>
						<li v-for="(item, index) in formItems" :key="item" :class="{active: (index === 0 && !activeId) || item.id === activeId}">
							<a href="#" :data-id="item.id" @click.prevent="dataId(item.id)">{{ item.itemName }}</a>
							<ValidateIcon></ValidateIcon>
						</li>
					</ul>
				</div>
				<!-- end fieldset-links-->
				<div class="controls align-bottom">
					<p class="required-field">Required field*</p>
					<ul>
						<li v-if="!getCurrentNode.hasChildren">
							<a class="remove" @click.prevent="removeNode">Delete <i class="fa-solid fa-delete-right"></i></a>
						</li>
					</ul>
				</div>
				<!-- bottom-controls -->
			</div>

			<div class="col right">
				<div class="fieldset-wrapper">
					<fieldset class="fieldset active" data-id="title">
						<HideFromUser fieldId="title-hide-from-user" :key="1" :checked="fieldValue('title', 'hideFromUser')" :value="'title'" @input="hideUserValue"></HideFromUser>
						<div class="subject-title form-item">
							<TextField :key="'nodeTitle'" :fieldId="'nodeTitle'" :fieldPlaceholder="'Node Title'" :isRequired="true" :valueProp="fieldValue('title', 'title')" @fieldUpdated="(value) => updateFieldValue($event, 'title', 'title', value)"></TextField>
						</div>
					</fieldset>

					<fieldset class="fieldset" data-id="details">
						<HideFromUser fieldId="details-hide-from-user" :key="2" :checked="fieldValue('details', 'hideFromUser')" :value="'details'" @input="hideUserValue"></HideFromUser>
						<div class="details form-item">
							<Textarea :fieldId="'details'" :fieldPlaceholder="'Details'" :isRequired="false" :valueProp="fieldValue('details', 'details')" @fieldUpdated="(value) => updateFieldValue($event, 'details', 'details', value)"></Textarea>
						</div>
					</fieldset>

					<fieldset v-if="!isCategory" class="fieldset" data-id="assign">
						<HideFromUser fieldId="assign-hide-from-user" :key="3" :checked="fieldValue('assign', 'hideFromUser')" :value="'assign'" @input="hideUserValue"></HideFromUser>
						<div class="assign form-item">
							<div class="styled-select select" id="author">
								<VueNextSelect v-model="assigned" class="form-control" :model-value="assign" :placeholder="fieldValue('assign', 'assign') ? fieldValue('assign', 'assign').fullName : 'Select User'" :options="getClientUsers" label-by="fullName" close-on-select @selected="(payload) => updateFieldValue($event, 'assign', 'assign', payload)"></VueNextSelect>
								<!-- <div class="error error-message">This field is required.</div> -->
							</div>
							<!-- end styled-select -->
						</div>
					</fieldset>

					<fieldset v-if="!isCategory" class="fieldset" data-id="deadline">
						<HideFromUser fieldId="deadline-hide-from-user" :key="4" :checked="fieldValue('deadline', 'hideFromUser')" :value="'deadline'" @input="hideUserValue"></HideFromUser>
						<div class="deadline form-item datepicker">
							<Datepicker v-model="deadlineDefault" class="form-control" dark autoApply format="dd/MM/yyyy HH:mm"></Datepicker>
						</div>
					</fieldset>

					<fieldset v-if="!isCategory" class="fieldset" data-id="keywords">
						<HideFromUser fieldId="keywords-hide-from-user" :key="5" :checked="fieldValue('keyword', 'hideFromUser')" :value="'keyword'" @input="hideUserValue"></HideFromUser>
						<div class="keywords form-item">
							<TextField :fieldId="'keywords'" :fieldPlaceholder="'Focus Keyword'" :isRequired="true" :valueProp="fieldValue('keyword', 'keyword')" @fieldUpdated="(value) => updateFieldValue($event, 'keyword', 'keyword', value)"></TextField>
						</div>
						<div class="reach form-item">
							<label>Reach</label>
							<TextField :fieldId="'reach'" :fieldPlaceholder="'Reach'" :isRequired="false" :valueProp="fieldValue('keyword', 'reach')" @fieldUpdated="(value) => updateFieldValue($event, 'keyword', 'reach', value)"></TextField>
						</div>
					</fieldset>

					<fieldset v-if="!isCategory" class="fieldset" data-id="format">
						<HideFromUser fieldId="format-hide-from-user" :key="6" :checked="fieldValue('format', 'hideFromUser')" :value="'format'" @input="hideUserValue"></HideFromUser>
						<div class="format form-item">
							<TextField v-model="format" :fieldId="'format'" :fieldPlaceholder="'Format'" :isRequired="true" :valueProp="fieldValue('format', 'format')" @fieldUpdated="(value) => updateFieldValue($event, 'format', 'format', value)"></TextField>
						</div>
					</fieldset>

					<fieldset v-if="!isCategory" class="fieldset" data-id="intent">
						<HideFromUser fieldId="intent-hide-from-user" :key="7" :checked="fieldValue('intent', 'hideFromUser')" :value="'intent'" @input="hideUserValue"></HideFromUser>
						<div class="intent form-item">
							<TextField v-model="ranking" :fieldId="'ranking'" :fieldPlaceholder="'Intent'" :isRequired="true" :valueProp="fieldValue('intent', 'intent')" @fieldUpdated="(value) => updateFieldValue($event, 'intent', 'intent', value)"></TextField>
						</div>
					</fieldset>

					<fieldset v-if="!isCategory" class="fieldset" data-id="research">
						<HideFromUser fieldId="research-hide-from-user" :key="8" :checked="fieldValue('research', 'hideFromUser')" :value="'research'" @input="hideUserValue"></HideFromUser>
						<div class="research form-item">
							<Textarea v-model="research" :fieldId="'research'" :fieldPlaceholder="'Research'" :isRequired="false" :valueProp="fieldValue('research', 'research')" @fieldUpdated="(value) => updateFieldValue($event, 'research', 'research', value)"></Textarea>
						</div>
					</fieldset>

					<fieldset class="fieldset" data-id="status">
						<HideFromUser fieldId="status-hide-from-user" :key="9" :checked="fieldValue('status', 'hideFromUser')" :value="'status'" @input="hideUserValue"></HideFromUser>
						<div class="status form-item">
							<div class="styled-select select legend-select">
								<VueNextSelect v-model="foundStatus" class="form-control" :modelValue="status" :options="status" label-by="status" close-on-select id="selectedStatus" @selected="(payload) => updateFieldValue($event, 'status', 'status', payload.status)">
									<template #label="{selected}">
										<template v-if="selected">
											<span class="color" :style="'background-color:' + selected.color"></span><span class="title">{{ selected.status }}</span>
										</template>
										<template v-else> <span class="color" :style="'background-color: #FF50A1'"></span><span class="title">Title Created</span> </template>
									</template>
									<template #dropdown-item="{option}">
										<span class="color" :style="'background-color:' + option.color"></span><span class="title">{{ option.status }}</span>
									</template>
								</VueNextSelect>
							</div>
							<!-- end styled-select -->
						</div>
						<div v-if="!isCategory" class="notes form-item">
							<Textarea v-model="notes" :fieldId="'notes'" :fieldPlaceholder="'Notes'" :isRequired="false" :valueProp="fieldValue('status', 'notes')" @fieldUpdated="(value) => updateFieldValue($event, 'status', 'notes', value)"></Textarea>
						</div>
					</fieldset>

					<Errors :errors="responseErrors" />
				</div>
				<!-- end fieldset-wrapper -->

				<!-- <div class="testing">
					<p>{{ hidefromuser }}</p>
					<p>Title: {{ fieldValue("title", "title") }}</p>
					<p>Details: {{ fieldValue("details", "details") }}</p>
					<p>Assign: {{ fieldValue("assign", "assign") }}</p>
					<p>Deadline: {{ fieldValue("deadline", "deadline") }}</p>
					<p>Keyword: {{ fieldValue("keyword", "keyword") }}</p>
					<p>Reach: {{ fieldValue("keyword", "reach") }}</p>
					<p>Format: {{ fieldValue("format", "format") }}</p>
					<p>Ranking: {{ fieldValue("intent", "intent") }}</p>
					<p>Research: {{ fieldValue("research", "research") }}</p>
					<p>Subject status: {{ fieldValue("status", "status") }}</p>
					<p>Notes: {{ fieldValue("status", "notes") }}</p>
				</div> -->
			</div>
		</form>
	</div>
	<!-- end add-subject-form -->
</template>

<script>
import VueNextSelect from "vue-next-select"
import "vue-next-select/dist/index.min.css"
import Datepicker from "vue3-date-time-picker"
import HideFromUser from "./inputs/HideFromUser.vue"
import TextField from "./inputs/TextField.vue"
import Textarea from "./inputs/Textarea.vue"
import Errors from "./validation/Errors.vue"
import ValidateIcon from "../forms/ValidateIcon.vue"
import useValidate from "@vuelidate/core"
//import {required} from "@vuelidate/validators"
import {mapGetters} from "vuex"
import {getClosest} from "../../assets/js/utils"

let main = null
let nodeForm = null
let nodeOverview = null

export default {
	name: "AddNodeForm",
	components: {
		Datepicker,
		VueNextSelect,
		HideFromUser,
		TextField,
		Textarea,
		ValidateIcon,
		Errors,
	},
	data() {
		return {
			v$: useValidate(),
			activeId: false,
			closed: true,
			formIsValid: true,
			responseErrors: [],
			formItems: [
				{id: "title", itemName: "Title"},
				{id: "details", itemName: "Details"},
				{id: "assign", itemName: "Assign"},
				{id: "deadline", itemName: "Deadline"},
				{id: "keywords", itemName: "Keywords"},
				{id: "format", itemName: "Format"},
				{id: "intent", itemName: "Intent"},
				{id: "research", itemName: "Research"},
				{id: "status", itemName: "Status"},
			],
			status: [
				//{status: "Show All", color: ""},
				{status: "Title Created", color: "#FF50A1"},
				{status: "Assigned", color: "#2ABBEE"},
				{status: "Pending Review", color: "#FF8344"},
				{status: "Published", color: "#18B28C"},
				{status: "Category", color: "#eee"},
			],
			hidefromuser: [],
			details: "",
			author: "",
			deadline: this.deadlineDefault,
			keywords: "",
			reach: "",
			format: "",
			ranking: "",
			research: "",
			selectStatus: "",
			notes: "",
			assign: "",
			userShownCloseWarning: false,
		}
	},
	validations: {
		/*assign: {
			//required: required,
		},*/
		/*selectStatus: {
			required: required,
		},*/
	},
	methods: {
		validate() {
			this.v$.$validate()
		},
		fieldStatus(validation) {
			return {
				error: validation.$error,
				valid: validation.$dirty,
			}
		},
		confirmCloseAddNode() {
			if (this.userShownCloseWarning || !this.hasCurrentNodeBeenEdited) {
				this.closeAddNode()
				this.userShownCloseWarning = false
			} else {
				alert("WARNING: Closing without saving will result in lost data!")
				this.userShownCloseWarning = true
			}
		},
		saveContentTree() {
			this.v$.$validate()
			//let formResponse = ""
			//let formResponse = await this.$store.dispatch("auth/login", {email: this.email, password: this.password})
			//this.responseErrors = [{$message: formResponse}]

			console.log(this.v$.$validate())

			let inputs = document.querySelectorAll(".form-control:not(.vue-select)")
			let labels = document.querySelectorAll(".fieldset-links ul li a")
			let selects = document.querySelectorAll(".vue-select.form-control")

			//inputs & textareas
			inputs.forEach((el) => {
				var fieldset = getClosest(el, ".fieldset")
				var data = fieldset.getAttribute("data-id")

				if (el.value != "" && !el.classList.contains("error")) {
					labels.forEach((label) => {
						if (label.getAttribute("data-id") == data) {
							label.nextElementSibling.classList.remove("invalid")
							label.nextElementSibling.classList.add("valid")
						}
					})
				} else if (el.value == "" && el.classList.contains("error")) {
					labels.forEach((label) => {
						if (label.getAttribute("data-id") == data) {
							label.nextElementSibling.classList.add("invalid")
							label.nextElementSibling.classList.remove("valid")
						}
					})
				}
			})

			//selects
			selects.forEach((el) => {
				var fieldset = getClosest(el, ".fieldset")
				var data = fieldset.getAttribute("data-id")
				// var dataValue = el.getAttribute("data-selected-length")

				// console.log(dataValue)

				if (el.value != "" && !el.classList.contains("error")) {
					labels.forEach((label) => {
						if (label.getAttribute("data-id") == data) {
							label.nextElementSibling.classList.remove("invalid")
							label.nextElementSibling.classList.add("valid")
						}
					})
				} else if (el.value == "" && el.classList.contains("error")) {
					labels.forEach((label) => {
						if (label.getAttribute("data-id") == data) {
							label.nextElementSibling.classList.add("invalid")
							label.nextElementSibling.classList.remove("valid")
						}
					})
				}
			})

			if (this.v$.$errors.length > 0) {
				this.userShownCloseWarning = false
				return (this.responseErrors = [{$message: "Form has errors, please check and try again."}])
			} else {
				this.responseErrors = [{$message: ""}]
				this.$emit("treeSaved")
				this.clearForm()
			}

			//this.closeAddNode()
			//this.openNodeOverview()
		},
		clearForm() {
			let labels = document.querySelectorAll(".fieldset-links ul li a")
			labels.forEach((label) => {
				label.nextElementSibling.classList.remove("valid")
				label.nextElementSibling.classList.remove("invalid")
			})
		},
		removeNode() {
			this.$emit("nodeRemoved")
			this.closeAddNode()
		},
		updateFieldValue($event, fieldGroup, field, payload) {
			let value = $event ? $event.target.value : payload
			this.$store.commit("client/updateNodeFieldValue", {fieldGroup, field, value})
		},
		hideUserValue($event) {
			let checked = $event.target.checked
			let fieldGroup = $event.target.value
			let pseudoEvent = {target: {value: checked}}
			this.updateFieldValue(pseudoEvent, fieldGroup, "hideFromUser")
		},
		dataId: function (id) {
			this.activeId = id

			let formFieldsets = null
			formFieldsets = document.querySelectorAll("fieldset")

			formFieldsets.forEach((el) => {
				el.classList.remove("active") //remove class from active item
				if (el.getAttribute("data-id") === id) {
					el.classList.add("active") //add class to the item with the same data id
				}
			})
		},
		closeAddNode() {
			if (nodeForm.classList.contains("form-open")) {
				main.classList.remove("node-form-open")
				main.classList.add("node-form-closed")
				nodeForm.classList.remove("form-open")
				nodeForm.classList.add("form-closed")
				this.clearForm()
			}
		},
		openNodeOverview() {
			// console.log("opening node overview")
			if (nodeOverview.classList.contains("overview-closed")) {
				main.classList.add("overview-open")
				main.classList.remove("overview-closed")
				nodeOverview.classList.add("overview-open")
				nodeOverview.classList.add("overview-closed")
			}
		},
		requiredCheck() {
			alert("this is required")
		},
	},

	// Next - two way coupling for updating node field value
	computed: {
		foundStatus() {
			return this.status.find((status) => status.status == this.fieldValue("status", "status"))
		},
		isCategory() {
			return  this.fieldValue("status", "status") === "Category"
		},
		assigned() {
			return this.getClientUsers.find((user) => user.username == this.fieldValue("assign", "assign").username)
		},
		legend() {
			return this.status.slice(1) //slice makes copy of status array instead of using original
		},
		fieldValue() {
			return (fieldGroup, fieldTitle) => {
				return this.$store.getters["client/getNodeFieldValue"](fieldGroup, fieldTitle)
			}
		},
		computedErrorMessage() {
			if (this.errorMessage.length > 0) {
				return this.errorMessage
			}
			return ""
		},
		deadlineDefault: {
			get() {
				//your validation
				return this.fieldValue("deadline", "deadline")
			},
			set(val) {
				let pseudoEvent = {target: {value: val.toString()}}
				this.updateFieldValue(pseudoEvent, "deadline", "deadline")
				this.deadline = val
			},
		},
		...mapGetters("views", ["getSidebarOpenState"]),
		...mapGetters("client", ["getCurrentNode", "getClientUsers", "hasCurrentNodeBeenEdited"]),
	},
	mounted() {
		main = document.getElementById("main")
		nodeForm = document.getElementById("add-node-form")
		nodeOverview = document.getElementById("node-overview")
	},
}
</script>
<style lang="scss">
@import "vue3-date-time-picker/src/Vue3DatePicker/style/main.scss";
@import "./../../assets/scss/components/addNodeForm.scss";
</style>
