<template>
	<span class="error error-message" v-for="error in errors" :key="error">{{ error.$message }}</span>
</template>

<script>
export default {
	name: "Errors",
	props: ["errors"],
	data() {
		return {
			hello: "world",
		}
	},
}
</script>
<style scoped></style>
