<template>
	<div class="validate" id="validateIcon">
		<span class="valid" title="Valid"><i class="fa-solid fa-circle-check"></i></span>
		<span class="invalid" title="Invalid"><i class="fa-solid fa-circle-xmark"></i></span>
	</div>
	<!-- end validate -->
</template>

<script>
export default {}
</script>
