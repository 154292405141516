<template>
    <div class="hide-from-user form-item">
        <label v-bind:for="fieldId" title="Hide from user">Hide from user
            <div class="checkbox-wrapper switch">
                <input type='checkbox' :checked="checked" v-bind:id='fieldId' :value="value" />
                <span class="slider"></span>
            </div><!-- end checkbox-wrapper -->
        </label>
    </div><!-- end form-item -->
</template>

<script>
export default {
    name: 'HideFromUser',
    props: {
        fieldId: {
            type: String,
            required: true,
        },
        value: String,
        checked: Boolean,
    },
    emits: ["change"],
}
</script>