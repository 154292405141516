<template>
	<div class="row">
		<textarea class="form-control autoExpand" rows='3' data-min-rows='3' :class="{error: v$.$error}" :name="fieldName" :id="fieldId" :placeholder="fieldPlaceholder" @blur="validate" :value="initialFieldValue" @input="updateFieldValue($event), v$.$touch()"></textarea>
		<Errors v-if="v$.fieldValue.$error" :errors="v$.$errors"></Errors>
	</div>
</template>

<script>
import useValidate from "@vuelidate/core"
import {requiredIf} from "@vuelidate/validators"
import Errors from "../validation/Errors.vue"

export default {
	name: "Textarea",
	data() {
		return {
			v$: useValidate(),
			fieldValue: this.value,
		}
	},
	props: {
		valueProp: {
			type: String,
			required: false,
		},
		fieldName: {
			type: String,
			required: false,
		},
		fieldPlaceholder: {
			type: String,
			required: true,
		},
		isRequired: {
			type: Boolean,
			required: false,
		},
		fieldId: {
			type: String,
			required: true,
		},
	},
	components: {
		Errors,
	},
	methods: {
		updateFieldValue($event) {
			this.fieldValue = $event.target.value
			this.$emit("fieldUpdated", this.fieldValue)
		},
		validate() {
			this.v$.$validate()
		},
		status(validation) {
			return {
				error: validation.$error,
				valid: validation.$dirty,
			}
		},
	},
	computed: {
		isOptional() {
			return this.isRequired
		},
		initialFieldValue() {
			return this.valueProp
		},
	},
	validations: {
		fieldValue: {
			requiredIf: requiredIf(function () {
				return this.isOptional
			}),
		},
	},
	updated() {
		this.fieldValue = this.valueProp
	},
}
</script>
